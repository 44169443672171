<template>
  <b-container fluid>
    <!-- NOTE separators -->
    <b-row>
      <b-col sm="2">
        <sidebarcontent />
      </b-col>
      <b-col sm="10">
        <!-- NOTE Gallery Desktop -->
        <b-row>
          <b-col
            v-if="isMobile"
            class="sticky-top bg-light"
            style="z-index: 9999"
          >
            <b-row class="py-3">
              <b-col
                cols="6"
                class="d-flex flex-column justify-content-center "
              >
                <b-icon
                  variant="dark"
                  class="h1"
                  style="margin:0"
                  v-b-toggle.sidebar-backdrop
                  icon="list"
                ></b-icon>
              </b-col>
              <b-col cols="6">
                <router-link to="/apply">
                  <b-button size="lg" variant="danger">Aplica ahora</b-button>
                </router-link>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="12">
            <b-row style="height:20em" class=" bg-danger mb-3">
              <b-col sm="12">
                <b-container
                  fluid
                  class="h-100 d-flex flex-column justify-content-center p-0"
                >
                  <b-row>
                    <b-col sm="6" class="p-0">
                      <div
                        v-if="isMobile"
                        class="title d-flex flex-row bg-light mb-3"
                      >
                        <p
                          class="h3 text-uppercase mx-auto align-self-center font-weight-light"
                        >
                          girl
                          <span class="font-weight-bold text-dark">power</span>
                          talk
                          <span class="font-weight-bold text-danger"
                            >méxico</span
                          >
                        </p>
                      </div>
                      <div
                        v-if="!isMobile"
                        class="title d-flex flex-row-reverse bg-light p-2"
                      >
                        <p
                          class="h3 text-uppercase mr-5 align-self-center font-weight-light"
                        >
                          girl
                          <span class="font-weight-bold text-dark">power</span>
                          talk
                          <span class="font-weight-bold text-danger"
                            >méxico</span
                          >
                        </p>
                      </div>
                    </b-col>
                    <b-col sm="6">
                      <div
                        style="height:4em"
                        class="d-flex flex-row ml-5"
                        :class="{
                          'mx-auto': isMobile,
                          'justify-content-center': isMobile,
                        }"
                      >
                        <p
                          class="h3 font-weight-bold text-uppercase align-self-center"
                        >
                          Conoce más
                        </p>
                      </div>
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" class="d-none d-sm-block mb-5 mx-0 px-0">
            <div class="container-gallery">
              <div v-for="index in 12" :key="index"></div>
            </div>
          </b-col>
          <b-col sm="12">
            <gallery
              :images="
                galleryPictures.map((element) => {
                  return element.img;
                })
              "
              :index="index"
              @close="index = null"
            ></gallery>
            <div class="gal">
              <div
                v-for="(picture, imageIndex) in galleryPictures"
                :key="imageIndex"
                @click="index = imageIndex"
                style="position:relative"
              >
                <img
                  style="position: relative"
                  :src="picture.img"
                  :alt="picture.title"
                />
                <div
                  class="content-gallery-card text-white d-flex flex-column justify-content-center h-100"
                >
                  <p class="align-self-center h5">{{ picture.title }}</p>
                  <p
                    class="align-self-center text-center mx-3"
                    style="font-size: small"
                  >
                    {{ picture.description }}
                  </p>
                </div>
              </div>
            </div>
          </b-col>
          <b-col
            v-if="loadingPictures"
            cols="12"
            class="d-flex flex-row justify-content-center my-5"
          >
            <div>
              <b-spinner
                variant="danger"
                style="width: 6rem; height: 6rem;"
                type="grow"
                label="Loading..."
              ></b-spinner>
            </div>
          </b-col>
          <b-col
            v-if="false"
            cols="12"
            class="d-flex flex-row justify-content-center my-5"
          >
            <div @click="addPictures" class="btn btn-danger">
              Cargar más imágenes
            </div>
          </b-col>
        </b-row>
        <!-- NOTE GET IN TOUCH  -->
        <b-row class="get-in-touch-bg my-md-5">
          <b-col
            style="height:14em"
            class="d-flex flex-column justify-content-center "
          >
            <div class="container">
              <div class="row my-3">
                <div class="col-12">
                  <p class="h3" :class="{ h5: isMobile }">
                    Si tienes una historia inspiradora, contáctanos.
                    #GirlPowerTalkMexico #GPTMexico
                  </p>
                </div>
              </div>
              <div class="row my-3">
                <div class="col-12">
                  <div class="btn btn-outline-danger">
                    <a href="#contacto" class="text-danger custom-link"
                      >Contáctanos</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <!-- NOTE Contact -->
        <b-row class=" pt-5">
          <b-col
            class="px-0 d-flex flex-row justify-content-end contact-bg"
            :class="{ 'mx-0': isMobile, 'mx-5': !isMobile }"
          >
            <contactform></contactform>
          </b-col>
        </b-row>
        <!-- NOTE Footer -->
        <b-row>
          <b-col class="px-0">
            <footerComponent></footerComponent>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import contactform from "@/components/ContactForm.vue";
import footerComponent from "@/components/Footer.vue";
import sidebarcontent from "@/components/SidebarContent.vue";
import VueGallery from "vue-gallery";
export default {
  name: "Galllery",
  components: {
    contactform,
    footerComponent,
    gallery: VueGallery,
    sidebarcontent,
  },
  data() {
    return {
      isMobile: false,
      index: null,
      loadingPictures: false,
      showSidebar: true,
      playingVideo: false,
      galleryPictures: [
        {
          img:
            "https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/gallery%2FIMG_2926-min.JPG?alt=media&token=0500d694-64c9-4bf9-82b3-8c987c90cac1",
          slideurl:
            "https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/gallery%2FIMG_2926-min.JPG?alt=media&token=0500d694-64c9-4bf9-82b3-8c987c90cac1",
          title: "Buscamos compartir experiencias",
          description: `
          Cada miembro de nuestro equipo, tiene algo valioso que aportar.
           \n Queremos aprender de todos.

                         
          `,
          date: "21 de enero 2021",
          size: "col-sm-12 col-md-2",
        },
        {
          img:
            "https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/gallery%2FIMG_2941-min.JPG?alt=media&token=1f870484-482b-45d6-ac09-bee28ed9df62",
          slideurl:
            "https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/gallery%2FIMG_2941-min.JPG?alt=media&token=1f870484-482b-45d6-ac09-bee28ed9df62",
          title: "Somos un familia",
          description: `
          Celebramos el trabajo en equipo, y más que un excelente equipo, buscamos construir una familia en GPT México.
                  
          `,
          date: "21 de enero 2021",
          size: "col-sm-12 col-md-2",
        },
        {
          img:
            "https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/gallery%2FIMG_2951-min.JPG?alt=media&token=5d864ca8-f5fb-4445-94b8-34ffc54b161b",
          slideurl:
            "https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/gallery%2FIMG_2951-min.JPG?alt=media&token=5d864ca8-f5fb-4445-94b8-34ffc54b161b",
          title: "El poder de levantar nuestras voces.",
          description: `
          Más que una empresa, buscamos dar la oportunidad de empoderar las voces que buscan ser escuchadas.
                   
          `,
          date: "21 de enero 2021",
          size: "col-sm-12 col-md-2",
        },
        {
          img:
            "https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/gallery%2FIMG_3016-min.JPG?alt=media&token=cb967428-880c-43e7-a1a1-c43cbce72500",
          slideurl:
            "https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/gallery%2FIMG_3016-min.JPG?alt=media&token=cb967428-880c-43e7-a1a1-c43cbce72500",
          title: "No una oficina, sino una segunda casa.",
          description: `
          Queremos construir el espacio donde puedas impulsar las ideas que cambien al mundo.
                 
          `,
          date: "21 de enero 2021",
          size: "col-sm-12 col-md-2",
        },
        {
          img:
            "https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/gallery%2FIMG_6180-min.JPG?alt=media&token=ab813111-a9a8-41be-8a8e-758ecaaa6428",
          slideurl:
            "https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/gallery%2FIMG_6180-min.JPG?alt=media&token=ab813111-a9a8-41be-8a8e-758ecaaa6428",
          title: "Trabajamos para lograr juntos una misma meta",
          description: `
          Creamos, innovamos y creemos firmemente en materializar nuestras ideas. Con nosotros, tus ideas siempre serán escuchadas.
                   
          `,
          date: "21 de enero 2021",
          size: "col-sm-12 col-md-2",
        },
        {
          img:
            "https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/gallery%2FIMG_6198-min.JPG?alt=media&token=1d33b40f-4575-4423-8f91-a764bd260159",
          slideurl:
            "https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/gallery%2FIMG_6198-min.JPG?alt=media&token=1d33b40f-4575-4423-8f91-a764bd260159",
          title: "Trabajo en equipo",
          description: `
          Es como nuestras metas se vuelven realidad, un espacio hecho para que tu voz, ayude a construir el futuro.
               
          `,
          date: "21 de enero 2021",
          size: "col-sm-12 col-md-2",
        },
        {
          img:
            "https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/gallery%2FIMG_6356-min.JPG?alt=media&token=95d09928-acea-4f28-8b3c-3a8a1bb3f944",
          slideurl:
            "https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/gallery%2FIMG_6356-min.JPG?alt=media&token=95d09928-acea-4f28-8b3c-3a8a1bb3f944",
          title: "Un lugar para crecer personalmente",
          description: `
          Queremos crecer juntos e impulsar no solo tus objetivos laborales, sino brindarte las herramientas para desarrollar tu potencial en cualquier ámbito.
                    
          `,
          date: "21 de enero 2021",
          size: "col-sm-12 col-md-2",
        },
        {
          img:
            "https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/gallery%2FIMG_6589-min.JPG?alt=media&token=ac60ec9b-b45e-4d46-ab67-73fe4cc00651",
          slideurl:
            "https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/gallery%2FIMG_6589-min.JPG?alt=media&token=ac60ec9b-b45e-4d46-ab67-73fe4cc00651",
          title: "Parte de nuestro equipo",
          description: `
          Siempre es un buen momento para planear y evaluar nuestros objetivos. Queremos crecer contigo.
                         
          `,
          date: "21 de enero 2021",
          size: "col-sm-12 col-md-2",
        },
        {
          img:
            "https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/gallery%2FIMG_7524-min.JPG?alt=media&token=a333e8bc-b6a2-455b-90a9-9491346e8053",
          slideurl:
            "https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/gallery%2FIMG_7524-min.JPG?alt=media&token=a333e8bc-b6a2-455b-90a9-9491346e8053",
          title:
            "Nuestro equipo trabajando en el sitio de Girl Power Talk India",
          description: `
          Tenemos un equipo multidisciplinario que explota sus habilidades al máximo.
                         
          `,
          date: "21 de enero 2021",
          size: "col-sm-12 col-md-2",
        },
        {
          img:
            "https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/gallery%2FIMG_7532-min.JPG?alt=media&token=f9c1320b-8105-4033-bc64-d11e32f6b195",
          slideurl:
            "https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/gallery%2FIMG_7532-min.JPG?alt=media&token=f9c1320b-8105-4033-bc64-d11e32f6b195",
          title: "Siempre buscamos mejorar",
          description: `
          Trabajamos siempre para mejorar lo que desarrollamos, y la comunicación nos ayuda a lograrlo.
                   
          `,
          date: "21 de enero 2021",
          size: "col-sm-12 col-md-2",
        },
      ],
    };
  },
  created() {
    this.isMobile = window.screen.width <= 540 ? true : false;
  },

  methods: {
    addPictures() {
      this.loadingPictures = true;
      for (let index = 0; index < 10; index++) {
        let newPicture = {};
        newPicture.title = "Hellou" + index.toString();
        newPicture.description = "this is  a new elemente in pictures gallery";
        newPicture.date = `${Date.now().s}`;
        newPicture.img =
          "https://source.unsplash.com/collection/19072" + index.toString();
        this.galleryPictures.push(newPicture);
      }
      this.loadingPictures = false;
    },
    playPauseVideo() {
      this.playingVideo === true
        ? this.$refs.landingVideo.pause()
        : this.$refs.landingVideo.play();
      this.showSidebar = !this.showSidebar;
      this.playingVideo = !this.playingVideo;
    },
  },
};
</script>

<style scoped>
@import "https://cdnjs.cloudflare.com/ajax/libs/baguettebox.js/1.8.1/baguetteBox.min.css";

.info-card {
  position: absolute;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  background-color: #15151500;
  transition: opacity 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: background-color 0.3s ease-in-out;
}
.info-card:hover {
  opacity: 1;
  background-color: #15151573;
}

.icons-style {
  min-height: 1em;
  height: 1em;
}

/* NOTE Gallery style*/

.container-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3px;
}

.container-gallery > div {
  min-height: 210px;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}

.container-gallery-grid-2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
}

.content-gallery-card {
  z-index: 2;
  width: 100%;
  position: absolute;
  top: 1px;
  cursor: pointer;
  opacity: 0;
  background-image: linear-gradient(#ffffff00, #ffffff00);
}
.content-gallery-card:hover {
  opacity: 1;
  background-image: linear-gradient(to left, #151515af, #151515b9);
  transition: opacity 0.7s ease-in-out, background-image 0.7s ease-in-out;
}

.container-gallery > div:nth-child(odd) {
  /* background-image: url(~@/assets/Gallery/bg-1.jpg); */
  background-image: url("https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/gallery%2FIMG_6119-min.JPG?alt=media&token=8549a4ec-cc72-4829-a83d-6658fe073d66");
}
.container-gallery > div:nth-child(even) {
  /* background-image: url(~@/assets/Gallery/bg-2.jpg); */
  background-image: url("https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/gallery%2FIMG_6139-min.JPG?alt=media&token=8469b006-638f-4e87-9080-e7e4618c7753");
}

.container-gallery > div:nth-child(1) {
  grid-column: 1 / 3;
}

.container-gallery > div:nth-child(4) {
  grid-row: 2 / 4;
}
.container-two {
  display: flex;
  background-image: linear-gradient();
}
.container-gallery > div:nth-child(8) {
  grid-row: 3 / 5;
}

.container-gallery > div:last-child {
  grid-column: 3 / 5;
}

@media screen and (max-width: 600px) {
  .container-gallery-grid-2 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

.gal {
  -webkit-column-count: 3; /* Chrome, Safari, Opera */
  -moz-column-count: 3; /* Firefox */
  column-count: 2;
  position: relative;
}
.gal img {
  width: 100%;
  padding: 4px 0 4px 0;
  position: relative;
}

@media (max-width: 500px) {
  .gal {
    -webkit-column-count: 1; /* Chrome, Safari, Opera */
    -moz-column-count: 1; /* Firefox */
    column-count: 1;
  }
}
.custom-link {
  text-decoration: none;
}
.custom-link:hover {
  color: #fff !important;
}
</style>
